import { Modal, Form, DatePicker, Select, Input, Button, Row, Col, Divider, Space } from "antd"
import styles from "./recruit.module.css"
import { updateVacancyRequests } from "../../Action/action";
import { useVacancyStore } from "../../store/store";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const convertDate = (date) => {
    const jsDate = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(jsDate);
    
    return formattedDate.replace(/-/g, '/'); // Replace dashes with slashes for the desired format
  };
export const arr = [
    {
        id: 1,
        label: "Recruitment type",
        type: "dropdown",
        value: ["Campus Recruitment", "Lateral Recruitment"],
        name:"recruitmentType"
    },
    {
        id: 2,
        label: "Priorty",
        type: "dropdown",
        value: ["Low", "Medium", "High"],
        name:"priority"
    },
    {
        id: 3,
        label: "Number of Vacancy",
        type: "input",
        typeof:"number",
        name:"vacancies"
    },
    {
        id: 4,
        label: "Recruitment Duration",
        type: "dateDuration",
        name:"recruitmentDuration"
    },
    {
        id: 5,
        label: "Preffered Candidate",
        type: "dropdown",
        value: ["Experinced", "Fresher"],
        name:"candidateType"
    },
    {
        id: 6,
        label: "Anticipated joining date",
        type: "date",
        name:"anticipatedJoinDate",
       
    },
    {
        id: 7,
        label: "Name",
        type: "input",
        name:"name"
    },
    {
        id: 8,
        label: "Mobile",
        type: "input",
        typeof:"number",
        name:"mobile"
    },
    {
        id: 9,
        label: "Email Id",
        type: "input",
        name:"email",
        typeof:"email",
    },
    {
        id: 10,
        label: "Reason for vacancy",
        type: "dropdown",
        value: ["Business expansion", "Replacement", "New project", "Skills gap", "Others"],
        name:"vacancyReason"

    },
    {
        id: 11,
        label: "Description",
        type: "input",

        name:"reasonDescription"
    }

]

const EditModal = (props) => {
 
    let datastored={...props.datas}
    if(datastored["anticipatedJoinDate"]){
        datastored["anticipatedJoinDate"]= dayjs(datastored["anticipatedJoinDate"],dateFormat)
    }
    if(datastored["recruitmentStartDate"]){
        datastored["recruitmentDuration"]=[dayjs(datastored["recruitmentStartDate"],dateFormat),dayjs(datastored["recruitmentEndDate"],dateFormat)]

    }
    console.log("enee",datastored)
    const {recruitmentData,defaultTemplate,updateRecruitmentData} = useVacancyStore(state=>state);
    return (
        <Modal centered={true} open={props?.editModal} onCancel={() => { props.setEditModal(false) }} onClose={() => props.setEditModal(false)} width={"80%"} style={{ top: 5 }} footer={false}>
            <div>
                <div className={styles.modalTitle} style={{marginBottom:16,fontSize:24}}>Edit Information</div>
                <Form  layout="vertical" onFinish={(values) => {
                    values["anticipatedJoinDate"]= convertDate(values["anticipatedJoinDate"])
                    if(values["recruitmentDuration"]){
                        values["recruitmentStartDate"]=convertDate(values["recruitmentDuration"][0])
                        values["recruitmentEndDate"]=convertDate(values["recruitmentDuration"][1])
                        delete( values["recruitmentDuration"])
                    //  values["recruitmentDuration"]=[convertDate(values["recruitmentDuration"][0]),convertDate(values["recruitmentDuration"][1])]
                    }
                    console.log("values", values)
                   let obj={...recruitmentData,...values}
                     updateRecruitmentData(obj)
                    //  props.setEditModal(false)
                     updateVacancyRequests(obj,(res,err) =>{
            
                     })
                  
                }} >
                    <Row gutter={16}>

                        {arr?.map((ele, index) => (
                            <>
                             {index==6?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div> Point of contact</div>
                                :index==9?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>  Justification</div>
                                :index===0?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>  Preferences</div>
                            :""}
                                <Col key={ele?.id} span={index > 8 ? 24 : 8}  >

                                    <Form.Item  initialValue={datastored[ele?.name]} key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {ele?.type == "dropdown" ?
                                            <Select placeholder={`Select ${ele?.label}`}>
                                                {ele?.value?.map(eles => (
                                                    <Select.Option value={eles}>{eles}</Select.Option>
                                                ))}

                                            </Select> : ele?.type === "date" ?
                                                <DatePicker  style={{ width: "100%" }} format="YYYY-MM-DD"/>
                                                : ele?.type === "dateDuration" ?
                                                    <RangePicker style={{ width: "100%" }} />
                                                    : <Input type={ele?.typeof} placeholder={`Enter ${ele?.label}`} />}

                                    </Form.Item>
                                </Col>
                              
                                {(index === 5 || index === 8) && <Divider style={{marginBottom:32}}/>}
                               
                            </>
                        ))}
                    </Row>
                    <Row>
                        <Col span={18}></Col>
                        <Col span={6}>
                            <Space>
                 <Button>Cancel</Button>
                 <Button htmlType="submit" type="primary">Save</Button>
                 </Space>
                 </Col>
                 </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default EditModal