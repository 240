import { create } from "zustand";
export const useStore=create(set=>({
    titleJob:'',
    titleIndustry:'',
    isModalOpen:false,
    jobExperience:'',
    jdData:'',
    progress:0,
    showExperince:false,
    setTitleJob:(val)=>set(state=>({titleJob:val})),
    setTitleIndustry:(val)=>set(state=>({titleIndustry:val})),
    setIsModalOpen:(val)=>set(state=>({isModalOpen:val})),
    setJobExperience:(val)=>set(state=>({jobExperience:val})),
    setJdData:(val)=>set(state=>({jdData:val})),
    setProgress:(val)=>set(state=>({progress:val})),
    setShowExperince:(val)=>set(state=>({showExperince:val}))

   
}))