import React, { useEffect, useState } from "react";
import { useVacancyStore } from "../store/store";
import JobCard, { statusBtnColor } from "./JobCard";
import FilterSiderbar from "./FilterSiderbar";
import { Button, Dropdown } from "antd";
import { Input, Space } from 'antd';
import { Select } from 'antd';
import { AppstoreOutlined, BarsOutlined, DownloadOutlined } from "@ant-design/icons";
import Pagination from "../customPagination";
import SearchCompo from "../searchComponent";
import Table from "../../../components/Table/Table";
import { getJobs,getVacancyRequests } from "../Action/action";
import { Link } from "react-router-dom";
import Divider from "../../../components/divider/Divider";
import no_job from '../../../assets/icons/no-job.svg'
import StatusBar from "./StatusBar";
const { Search } = Input;

const Jobs=({status})=>{

  const {nFilters,setnFilters, jobsResponse,setJobsResponse,layoutType,setLayoutType,jobFilters,setJobFilters} = useVacancyStore(state=>state);
  const [search,setSearch]=useState();
  const [sortBy,setSortBy]=useState('ASC');
  const [loading,setLoading]=useState(true);



useEffect(()=>{
  setnFilters()
  setJobsResponse()

},[status])
  
const getAllJobs=()=>{
  let params={
    status:status,
    search:search,
    sortBy:sortBy,
    ...nFilters,
    page: jobsResponse?.meta?.currentPage||1,
    limit: jobsResponse?.meta?.itemsPerPage||10,
  }
  setLoading(true);
  getJobs(params,(response,error)=>{
    setJobsResponse(response)
    setJobFilters(response?.filterTypes)
     setLoading(false);
  })
}


useEffect(()=>{
  
  getAllJobs();
},[search,sortBy,status,jobsResponse?.meta?.currentPage,jobsResponse?.meta?.itemsPerPage,nFilters])

const handlePageChange = (page) => {
  setJobsResponse({
    ...jobsResponse,  
    meta: {
      ...jobsResponse.meta,  
      currentPage: page      
    }
  });


};
const handleItemsPerPageChange = (size)=>{
setJobsResponse({
  ...jobsResponse,   
  meta: {
    ...jobsResponse.meta,  
    currentPage: 1 ,    
    itemsPerPage: size,
    totalPage:0,
    totalItems:0,
    itemCount:0 
  }
});



};
const onStatusSelect=(res)=>{
  alert(res)
}


// {
//   meta:{
//     title:'Status',
//     type:'dropdown',
//    isMultiSelect:true,
//    selectAll:true,
//     styles:{
//       width:'50px'
//     },
//     options:[
//       {
//         id:1,
//         name:"DRAFT"
//       },
//       {
//         id:2,
//         name:'PENDING'
//       },
//       {
//         id:3,
//         name:'PENDING'
//       },
//       {
//         id:4,
//         name:'PENDING'
//       },
//       {
//         id:5,
//         name:'PENDING'
//       },
//        {
//         id:6,
//         name:'PENDING'
//       },
//       {
//         id:7,
//         name:'PENDING'
//       },
//       {
//         id:8,
//         name:'PENDING'
//       }
//     ]
//   },
//   handler:onStatusSelect
// }
const columns=[
  {
    meta:{
      title:'Job Title',
      type:'default',
      styles:{
        width:'200px'
      },
   
    },
   
  },
 
  status=='active'? {
    meta:  {
      title:'Stage',
      type:'default',
      styles:{
        width:'250px'
      },
    }
  }:{
    meta:  {
      title:'Status',
      // isMultiSelect:true,
      type:'default',
      styles:{
        width:'180px'
      },
      // options:[
      //   {
      //     key:'Draft',
      //     value:'Draft'
      //   },
      //   {
      //     key:'Pending',
      //     value:'Pending'
      //   },
      //   {
      //     key:'Activated',
      //     value:'Activated'
      //   },
      //   {
      //     key:'Denied',
      //     value:'Denied'

      //   },
              
      // ]
    
  },
  handler:(a)=>{alert(a)}

  },
  {
    meta:{
      title:'Job Code',
      type:'default',
      styles:{
        width:'160px'
      },
    }
  },
  {
    meta:{
      title:'Job Type',
      type:'default',
      styles:{
        width:'160px'
      },
    }
  },
  {
    meta:{
      title:'Department/Category',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Team',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Job Location',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Work Arrengements',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Industy-specification',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Minimum years of experince',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Maximum years of experince',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Preferred Notice Period',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Travel Involvement',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Minimum range',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Maximum range',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Recruitment Type',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Priority',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Number of Vacancies',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Preferred Candidate',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Point of Contact(Name)',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Reason for vacancy',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
]


const data=jobsResponse?.jobs?.map(data=>{
   return  [
      {renderItem:<Link to={`/vacancies/${data.id}`} style={{color:'#1890FF',fontSize:'14px',fontWeight:700}}>{data.jobTitle??'-'}</Link>},
      {renderItem:status=='active'?     <StatusBar percent={data?.percentage??0} 
      bars={data?.template?.stage?.map(e=>e?.stageKey)}
      stage={data?.stage}
      />:
      <><button className="inactive-status" style={{background:statusBtnColor(data?.status)?.[0],color:statusBtnColor(data?.status)?.[1],width:'124px'}} >{data?.status?.toUpperCase()}</button></>},
      {renderItem:<div>{data?.jobCode??'-'}</div>},
      {renderItem:<div>{data?.jobType??'-'}</div>},
      {renderItem:<div>{data?.department??'-'}</div>},
      {renderItem:<div>{data?.team??'-'}</div>},
      {renderItem:<div>{data?.location??'-'}</div>},
      {renderItem:<div>{data?.workArrengements??'-'}</div>},
      {renderItem:<div>{data?.industrySepcification??'-'}</div>},
      {renderItem:<div>{data?.minExperience??'-'}</div>},
      {renderItem:<div>{data?.maxExperience??'-'}</div>},
      {renderItem:<div>{data?.noticePeriod??'-'}</div>},
      {renderItem:<div>{data?.travelInvolvement??'-'}</div>},
      {renderItem:<div>{data?.minRange??'-'}</div>},
      {renderItem:<div>{data?.maxRange??'-'}</div>},
      {renderItem:<div>{data?.recruitmentType??'-'}</div>},
      {renderItem:<div>{data?.priority??'-'}</div>},
      {renderItem:<div>{data?.vacancies??'-'}</div>},
      {renderItem:<div>{data?.preferredCandidate??'-'}</div>},
      {renderItem:<div>{data?.poc??'-'}</div>},
      {renderItem:<div>{data?.reason??'-'}</div>},
    
     ]
  })






    return (
        <>
        <div className="jobs-wrapper">
        <FilterSiderbar loading={loading} />
        
      
        <div className="jobs-container">
            <div className="jobs-filter-1">
            {/* <Search placeholder="Search" onSearch={onSearch} enterButton style={{maxWidth:'300px'}}/> */}
            <SearchCompo onSearch={(value)=>{
              console.log("ddddd",value);
                // setPageMeta({currentPage:1,
                //   itemsPerPage:10})
                  if(!loading)
                 setSearch(value)
              }}  /> 
            <Button   color="primary" variant="outlined"   style={{cursor:loading?'not-allowed':'pointer'}}><DownloadOutlined />Export</Button>
                
            </div>
            <div className="jobs-filter-2">
            <div className="jobs-count">Showing: <span> {jobsResponse?.meta?.totalItems??0} Jobs</span> </div>
            <div className="jobs-filter-wrapper">
            <div className="jobs-sort">
             <div>Sort by: </div>
             <Select
                showSearch
                style={{
                width: 200,
                color:'#1F1F1F'
                }}
                placeholder="Select"
                optionFilterProp="label"
                defaultValue={  {
                  value: 'ASC',
                  label: 'Newest to Oldest',
                }}
                onChange={(value)=>{
                  setSortBy(value)
                }}
             
                disabled={loading}
                options={[
                {
                  value: 'ASC',
                  label: 'Newest to Oldest',
                },
                {
                  value: 'DESC',
                  label: 'Oldest to Newest',
                },
                {
                  value: 'Alpha-ASC',
                  label: 'Job Title (A-Z)',
                },
                {
                  value: 'Alpha-DESC',
                  label: 'Job Title (Z-A)',
                },

                ]}
              />
            </div>
            <div className={`layout-btn ${layoutType=='grid'?'active':''}`} onClick={()=>{
              setLayoutType('grid')
            }}>
            <AppstoreOutlined style={{color:layoutType=='grid'?'white':'black'}} />
            </div>
            <div className={`layout-btn ${layoutType=='list'?'active':''}`} onClick={()=>{
              setLayoutType('list')
            }}>
            <BarsOutlined  style={{color:layoutType=='list'?'white':'black'}}/>
            </div>
            </div>
         
                
            </div>

         
            <>
            {layoutType=='grid'?
              <div className="jobcard-wrapper">
              {!loading?
                jobsResponse?.jobs?
                jobsResponse?.jobs?.map((job,index)=>{
                
                  return <JobCard data={job} status={status} />
                })
                :
                <>
              <div className="no-job-data">
                 <img src={no_job}/>
                 <h3>Oops! Nothing matches your search.</h3>
                 <p>Try adjusting your search terms or use different keywords for better results.</p>
              </div>
             </>

              :<>
              {[...new Array(6)].map(e=>{
             return  <div   className="jobcard-container" >

                  <div className="jobcard-header">
                  <div>
                  <h3 className="jobcard-title animated-bg animated-bg-text" style={{width:'180px',height:'15px'}}></h3>
                  <span className="jobcard-field animated-bg animated-bg-text" style={{width:'120px',height:'12px',marginTop:'10px'}}></span>
                  </div>
                          {status=='inactive'?<button  className="inactive-status animated-bg animated-bg-text" style={{width:'60px',height:'25px'}}></button>:null}

                  </div>


                  <div className="jobcard-spec">
                      <div className="jobcard-exp" >
                          <div className=" animated-bg animated-bg-text" style={{width:'10px',height:'10px',borderRadius:'2px',marginRight:'2px'}}></div>
                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                      <div className="jobcard-exp" >
                          <div className=" animated-bg animated-bg-text" style={{width:'10px',height:'10px',borderRadius:'2px',marginRight:'2px'}}></div>
                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                  </div>

                    <Divider/>


                  <div className="jobcard-info">
                  <div className="jobcard-exp" >
                      
                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                     
                      <div className="jobcard-exp" >

                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                  </div>
                  <div className="jobcard-status">
                  <div className=" animated-bg animated-bg-text" style={{width:'100%',height:'15px'}}></div>
                  <div className="jobcard-exp" >
                          <div className=" animated-bg animated-bg-text" style={{width:'10px',height:'10px',marginRight:'2px'}}></div>
                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                  </div>
                  <div className="jobcard-date">
                  <div className=" animated-bg animated-bg-text" style={{width:'60%',height:'10px'}}></div>
                  </div>


              </div>
            })}
              </>
              }
            </div>:
           
             <Table columns={columns} rowData={data} loading={loading} scrollMore={true}/>
            }
           

          
            <div className="jobs-pagination">
            <Pagination
              totalPages={jobsResponse?.meta?.totalPages}
              currentPage={jobsResponse?.meta?.currentPage}
              onPageChange={handlePageChange} 
              itemsPerPage={jobsResponse?.meta?.itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
             />
             </div>
           </>
             
            

        </div>
        </div>
           

        </>
    );
}
export default Jobs;