// import React, { useState } from 'react';
// import Select from 'react-select';
// import "./createRequests.css";
// import { useFlagStore } from './store/store';



// const SearchableSelect = ({options, placeholder,title,onChange,inputVal}) => {
//     const [selectedOption, setSelectedOption] = useState(inputVal??'');
//     const {viewFormModal} = useFlagStore(state=>state);

//     const handleChange = (event) => {
//         console.log(event.value,"SELECTCHECK")
//         setSelectedOption(event);
//         onChange(event.value)
//     };

//     return (
//         <div className='input-group'>
//             <label htmlFor="searchable-select" >
//                 {title}
//             </label>
//             {(viewFormModal===false)&&<Select
//                 id="searchable-select"
//                 value={selectedOption}
//                 onChange={handleChange}
//                 options={options}
//                 placeholder={placeholder}
//                 isSearchable
//                 styles={{
//                     control: (provided) => ({
//                         ...provided,
//                         borderRadius: '4px',
//                         borderColor: '#d9d9d9',
//                     }),
//                     option: (provided, state) => ({
//                         ...provided,
//                         backgroundColor: state.isSelected ? '#e6f7ff' : 'white',
//                         color:'#000',
//                         '&:hover': { backgroundColor: '#f2f2f2' },
                       
//                     }),
//                     placeholder: (provided) => ({
//                         ...provided,
//                         color: '#d0d0d0', // Set your custom placeholder color here
//                         height:'17px'
//                     }),
                
//                 }}
//             />}
//             {(viewFormModal===true)&&(
//                 <div style={{color:'#1f1f1f'}}>
//                     {inputVal??'-'}
//                 </div>
//                 )}
//         </div>
//     );
// };

// export default SearchableSelect;
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import "./createRequests.css";
import { useVacancyStore } from './store/store';



const SearchableSelect = ({options, placeholder,title,onChange,inputVal,isRequired=false}) => {
  console.log(options,"APPROVEROPTIONS")

  const getDefaultOption = (inputVal) => {
    if(title=="Reason for vacancy"){
     console.log("REASONVACANCY",inputVal)
        return options.find(option => option.value.toLowerCase() == inputVal.toLowerCase()) || null;

    }
    else{
    return options.find(option => option.value === inputVal) || null; // Use null if no match is found
    }
  };

  

  const [selectedOption, setSelectedOption] = useState(getDefaultOption(inputVal));

   // const [selectedOption, setSelectedOption] = useState(inputVal??'');
    const {viewFormModal} = useVacancyStore(state=>state);

  const handleChange = (event) => {
    setSelectedOption(event);
    onChange(event.value); // Pass the value to parent component
  };
 
  return (
    <div className='input-group'>
      <label htmlFor="searchable-select">
        {title}{isRequired && <span style={{ color: '#F5222D' }}>*</span>}
      </label>
      {viewFormModal === false && (
        <Select
          id="searchable-select"
          value={selectedOption} // This now shows the correct label
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isSearchable
          required={title === "Recruitment type"}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: '4px',
              borderColor: '#d9d9d9',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#e6f7ff' : 'white',
              color: '#000',
              '&:hover': { backgroundColor: '#f2f2f2' },
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#d0d0d0', // Set your custom placeholder color here
              height: '17px',
            }),
          }}
        />
      )}
      {viewFormModal === true && (
        <div style={{ color: '#1f1f1f' }}>
          {selectedOption ? selectedOption.label : '-'}
        
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
