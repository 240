import React from "react";
import "./summaryCard.css";
import SummaryIcon from "../../../../assets/summaryIcon.svg"
import infoIcon from "../../../../assets/infoIcon.svg"
import StatusBar from "../../jobs/StatusBar";

const SummaryCard = ()=>{

    return (
        <div className="summarycard-container">
            <div className="summarycard-subcontainer1">
                <div><img src={SummaryIcon} alt="SummaryIcon"/></div>
                <div>
                    <div>
                    Pipeline Stage Summary
                    </div>
                    <div>
                    An overview of candidates' progression in the hiring process.
                    </div>
                </div>
            </div>
            <div className="summarycard-subcontainer2">
                <div>{0}</div>
                <div>Active candidates</div>
                <div style={{display:'flex',flexDirection:'row',gap:'0.5rem'}}>
                    <div>% success rate</div>
                    <div><img src={infoIcon} alt="InfoIcon" className="img-summarycard"/></div>
                </div>
            </div>
            <div  className="summarycard-subcontainer3">
                <StatusBar/>
                33.33% of active candidates are currently in New
            </div>

        </div>
    );

}

export default SummaryCard;