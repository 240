import React, { useState,useEffect } from "react";
import styles from "./recruit.module.css"
import HiringStages from "./hiringStages";
import EditPen from "../../../../assets/editPen.svg"
import EditModal from "./editModal";
import RecruitAddModal from "./recuitmentAddModal";
import Add from "../../../../assets/plusIcon.svg"
import Preference from "../../../../assets/preferenceIcon.svg"
import RecruitPartner from "../../../../assets/RecruitPartner.svg"
import RecruitTeam from "../../../../assets/recruitTeam.svg"
import Poc from "../../../../assets/POC.svg"
import Justification from "../../../../assets/InformationLetter.svg"
import { arr } from "./editModal";
import { useGet } from "../../../../custom-hooks/useApi"
import { useVacancyStore } from "../../store/store";

 const style1 = {
    marginLeft: "2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", height: "40px", width: "40px",
    cursor: "pointer"


}
const RecruitmentOverview = () => {
    const {updateDefaultTemplate,updateTemplateDropdown,updateRecruitmentData,recruitmentData} = useVacancyStore(state=>state);
    const [editModal, setEditModal] = useState(false)
    const [recruitAddModal, setEditRecruitModal] = useState("")
    const datas=window.location.pathname.split("/vacancies/")
    
    
    const {data:data1,error:error}=useGet("/api/hiring/recruitmentOverview",{jobId:datas[1]})
   useEffect(() =>{
  updateDefaultTemplate(data1?.templates)
  let dataStored={}
  if(data1?.job){
   dataStored={...data1?.job}
  dataStored["recruitmentDuration"]=[dataStored["recruitmentStartDate"],"-",dataStored["recruitmentEndDate"]]
  }
  updateRecruitmentData(dataStored)
  updateTemplateDropdown(data1?.dropdown || [])
//    console.log("data",data1,defaultTemplate)
   },[data1])

    return (
        <div className={styles.container} >
            <div style={{ width: "30%", }}>
                <div className={styles.card} style={{ minHeight: "42vh",padding:12  }}>

                   <div style={{display: "flex", flexDirection: "row",}}>
                    <img src={Preference} height={"10%"} />
                    <div className={styles.modalTitle} style={{ margin: 10, width: "70%" }}>
                        Preferences</div>


                    
                    <div style={{ cursor: "pointer", margin: 10 }} onClick={() => {
                        setEditModal(true)
                    }}><img src={EditPen} />
                    </div>
                    </div>
                    <div>
                    {
                        arr.slice(0,6).map((ele,index) =>{
                            return(
                                <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent:"space-between",marginTop:16}}>
                                    
                                <div style={{fontSize:"14px",color:"#7A7A7A"}}>
                                    {ele?.label}</div>
                                   
                                <div  style={{fontSize:"14px",color:"#7A7A7A"}}>{recruitmentData[ele?.name]!==null?recruitmentData[ele?.name]:"--"}</div>
                                </div>
                            )
                        })
                    }
                    <div style={{display: "flex", flexDirection: "row",width:"100%",marginTop:16}}>
                    <img src={Poc} height={"100%"} />
                    <div className={styles.modalTitle} style={{ margin: 11, width: "70%", }}>
                        Point of contact</div>


                    
                    </div>
                    {
                        arr.slice(6,9).map((ele,index) =>{
                            return(
                                <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent:"space-between",marginTop:16}}>
                                    
                                <div style={{fontSize:"14px",color:"#7A7A7A"}}>
                                
                                    {ele?.label}</div>
                                <div  style={{fontSize:"14px",color:"#7A7A7A"}}>{recruitmentData[ele?.name]||"--"}</div>
                                </div>
                            )
                        })
                    }
                    <div style={{display: "flex", flexDirection: "row",width:"100%",marginTop:16}}>
                    <img src={Justification} height={"100%"} />
                    <div className={styles.modalTitle} style={{ margin: 11, width: "70%" }}>
                        Justification</div>


                    
                    </div>
                    <div  style={{fontSize:"14px",color:"#7A7A7A"}}>{recruitmentData["vacancyReason"]||"--"}</div>
                    <div  style={{fontSize:"14px",color:"#7A7A7A"}}>{recruitmentData["reasonDescription"]||"--"}</div>
                    
                    </div>
                </div>
                <div className={`${styles.recruitModalCardAlign} ${styles.card}`} style={{ marginTop: 24,padding:12 }}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <img src={RecruitPartner} height={"40px"} />
                        <div style={{ margin: 10, width: "70%" }}>
                            Recruitment Partner</div>

                    </div>
                    <div>
                        <div
                            className={styles.dashedCircle}
                            style={style1}
                            onClick={() => {
                                setEditRecruitModal("partner")
                            }}>
                            <img src={Add} height={"50%"} />

                        </div>
                        <div style={{ color: "#1890FF", marginLeft: "10px" }}>Add</div>
                    </div>
                </div>

                <div className={`${styles.recruitModalCardAlign} ${styles.card}`} style={{ marginTop: 24,padding:12,marginBottom:70 }}>
                <div style={{ display: "flex", flexDirection: "row", }}>
                        <img src={RecruitTeam} height={"40px"} />
                        <div style={{ margin: 10, width: "70%" }}>
                            Recruitment Team</div>

                    </div>
                    <div>
                        <div
                            className={styles.dashedCircle}
                            style={style1}
                            onClick={() => {
                                setEditRecruitModal("team")
                            }}>
                            <img src={Add} height={"50%"} />

                        </div>
                        <div style={{ color: "#1890FF", marginLeft: "10px" }}>Add</div>
                    </div>
                </div>
            </div>
            <div className={styles.card} style={{ width: "60%", marginLeft: 20,padding:12 }}>
                <HiringStages  />
            </div>
            {editModal && <EditModal editModal={editModal} setEditModal={setEditModal} datas={recruitmentData}/>}
            {recruitAddModal !== "" && <RecruitAddModal recruitAddModal={recruitAddModal} setEditRecruitModal={setEditRecruitModal} />}
        </div>
    )

}
export default RecruitmentOverview