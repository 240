import React, { useEffect } from "react";
import { useVacancyStore } from "./store/store";



const CustomTabs = ({tabs}) =>{

    // const [activeTab, setActiveTab] = useState(tabs[0].name);
    const {activeTab,setActiveTab} = useVacancyStore(state=>state);
    useEffect(()=>{
     setActiveTab(tabs?.[0]?.name)
    },[])

    //const contentRefs = useRef([]); // Store refs for tab contents


    // const handleTabClick = (tabName, index) => {
    //     setActiveTab(tabName);
    //     //contentRefs.current[index].scrollIntoView({ behavior: "smooth" });
    //   };

    const renderContent = () => {
        const activeTabContent = tabs.find(tab => tab.name === activeTab);
        return activeTabContent ? activeTabContent.content : null;
    };

    useEffect(()=>{
        renderContent();
    },[activeTab])

    return (
      <>
            <div style={{ display: "flex",flexDirection:'column'}}>
            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            {tabs.map((tab,index)=>(
          
              <div
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              style={{
                padding: "10px 20px",
                cursor: "pointer",
                //backgroundColor: activeTab === tab ? "#007bff" : "#f0f0f0", // Highlight active tab
                color: activeTab === tab.name ? "#1890FF" : "#D0D0D0",
                borderRadius: "5px",
                marginRight: "10px",
                display: "flex",
                padding: 'var(--XS-4XS, 10px) var(--S-3S, 16px)',
                alignItems: 'center',
                gap: 'var(--XS-3XS, 8px)',
                marginLeft:'1rem',
                flexDirection:'row',
                position:'relative'
              }}
            >
              {activeTab===tab.name?<div style={{width:'19px',height:'19px'}}>{tab.icon}</div>:null}
              <div>{tab.name}</div>
              {
                activeTab === tab.name?    
                 <div style={{width: '100%',
                          height:'4px', 
                          backgroundColor:'#1890FF', 
                         position:'absolute',
                         bottom:'0'
                        }}>
                      
                </div>:null
              }
         
              
            </div>


            ))}
            </div>
          
            
            <div style={{width:'95%', height:'1px', backgroundColor:'#E9E9E9', marginLeft:'1rem'}}></div>
            </div>
           
           
             {/* Active Tab Content */}
          
           
        
      <div style={{width:'100%',height: "max-content"}} >
        {renderContent()}
      </div>
           
       </>
    );
}

export default CustomTabs;