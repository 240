import React from "react";
import CustomTabs from "../CustomTabs";

import { useVacancyStore } from "../store/store";
import JobOverview from "./job_overview/jobOverview";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ActivitiesPage from "./activities/activity";
import RecruitmentOverview from "./recruitmentOverview/recruitmentOverview";
import activitiesIcon from "../../../assets/activitiesIcon.svg"

function VacancyDetails ()
{

    const navigate=useNavigate();

    const {setActiveTab,vacancyData} = useVacancyStore(state=>state);
    const tabs = [
        { 
          name: "Job Overview", 
          content: <JobOverview/>,
          style:{
            width:'160px',
            marginLeft:'0px',
          },
          icon:<img src={activitiesIcon}/>
        },
        { 
          name: "Recruitment Overview", 
          content: <RecruitmentOverview/>,
          style:{
            width:'160px',
            marginLeft:'320px',
          },
          icon:<img src={activitiesIcon}/>
        },
        { 
          name: "Activities", 
          content:   <ActivitiesPage/>,
          style:{
            width:'100px',
            marginLeft:'420px',
          },
          icon:<img src={activitiesIcon}/>
        },
     
      ];



    return (
   
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px',}}>
    
        <div style={{display:'flex',flexDirection:'row',gap:'10px'}} >
        <div style={{marginTop:'4px',width:'30px',cursor:'pointer'}} onClick={()=>{
            navigate('/vacancies')
        }}>
            <ArrowLeftOutlined style={{fontSize:'18px'}}/>
        </div>
        <div>
            <h3 style={{margin:'3px 0px'}}>{vacancyData?.jobTitle}</h3>
            <p style={{margin:0}}>Vacancies/Active</p>
        </div>
        </div>
      
      
            </div>
         <CustomTabs tabs={tabs}/>
         

    
        
    </div>
       
    );
}

export default VacancyDetails;