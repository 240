import React, { useEffect, useState } from "react";
import "./activities.css"
import SummaryCard from "./summaryCard";
import axios from "axios";
import activitiesNotes from "../../../../assets/activitiesNotes.svg"
import { Select } from "antd";
import { Timeline } from "antd/lib";
import CustomTimeline from "./timelineItem";
import CustomCarousel from "./customCarousel";
import NewCard from "./newCard";
import ResumeCandidate from "./resumeCandidates";
import CustomBarChart from "./customBarChart";
import { getVacancyActivities } from "../../Action/action";
import { useActivitiesStore } from "../../store/store";
import { useParams } from "react-router-dom";

const ActivitiesPage = ()=>{

  const {id} = useParams();

    const [selectedOption, setSelectedOption] = useState([]);
    const {activitiesData, setActivitiesData}  = useActivitiesStore(state=>state);

const handleSelectChange = (value)=>{
    console.log(value,"CHECKCHECK12345")
    setSelectedOption(value)
}


    const options =[
        {
            value: '03-2024',
            label: 'March 2024',
        },
        {
            value: '04-2024',
            label: 'April 2024',
        },
        {
            value: '3',
            label: 'May 2024',
        },
        ];

     
const timelineData1 = [
    {
      title: 'Create a services site 2015-09-01',
      color: '#1890FF',
      subItems: ['Initial setup', 'Requirement gathering'],
       date: 'Mar 03 2024 13:52:41'
    },
    {
      title: 'Solve initial network problems 2015-09-01',
      color: '#1890FF',
      subItems: ['Identify network issues', 'Implement fixes'],
       date: 'Mar 03 2024 13:52:41'
    },
   
  ];
  const timelineData2 = [
    {
        title: 'Technical testing 2015-09-01',
        color: '#1890FF',
        subItems: ['Unit testing', 'Integration testing', 'Performance testing'],
         date: 'Apr 03 2024 13:52:41'
      },
      {
        title: 'Network problems being solved 2015-09-01',
        color: '#1890FF',
        subItems: ['Status: In Progress', 'Expected Completion: 2015-09-05'],
        date: 'Apr 03 2024 13:52:41'
      },
  ];
  const timelineData3 = [
    {
        title: 'Technical testing 2015-09-01',
        color: '#1890FF',
        subItems: ['Unit testing', 'Integration testing', 'Performance testing'],
         date: 'Apr 03 2024 13:52:41'
      },
   
  ];
//   const sections = [
//     { label: 'March 2024', data: timelineData1},
//     { label: 'April 2024', data: timelineData2},
//     { label: 'May 2024', data: timelineData3},
//   ];

//   const filteredSections = sections?.filter((section) =>
//     selectedOption?.some((option) => option?.label === section?.label)
//   );

  const slides= [
<NewCard/>,<ResumeCandidate/>
  ];


  useEffect(()=>{
    let params = {
        jobId: id
    }
    getVacancyActivities(params,(response,error)=>{
        if (error) {
          console.error("Error geting activities:", error);
        } else {
        
          setActivitiesData(response?.result)
          
          console.log("Activities check :",activitiesData);
        }
      })
  },[selectedOption])

  const formattedData = {};
  for (const [key, value] of Object.entries(activitiesData)) {
    // Split the month and year
    const [month, year] = key.split('-');
    
    // Create a date string formatted as "Month Year"
    const date = new Date(year, month - 1);
    const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
    // Assign the activities (or other data) to the new formatted key
    formattedData[formattedDate] = value || [];
  }
// const formattedData = {
//     "November 2024": {
//       activities: [
//         { "info": "Published vacancy 106", "createdAt": "Mon Nov 25 2024 00:00:00 GMT+0530 (India Standard Time)" },
//         { "info": "Updated location for 105", "createdAt": "Wed Nov 20 2024 00:00:00 GMT+0530 (India Standard Time)" },
//         { "info": "Created vacancy 104", "createdAt": "Fri Nov 15 2024 00:00:00 GMT+0530 (India Standard Time)" },
//         { "info": "Deleted vacancy 103", "createdAt": "Sun Nov 10 2024 00:00:00 GMT+0530 (India Standard Time)" },
//         { "info": "Updated salary for 102", "createdAt": "Tue Nov 05 2024 00:00:00 GMT+0530 (India Standard Time)" }
//       ],
//       loadMore: true,
//       page: 1
//     },
//     "October 2024": { loadMore: false, page: 1 },
//     "September 2024": { loadMore: false, page: 1 }
//   };
  
 
  //console.log(formattedData,"ACTIVITIES");
  const sections = Object.entries(formattedData)?.map(([key, value]) => ({
    label: key,
    data: value
  }));
  
  console.log(sections,"ACTIVITIES")
  

//   const options = Object.entries(activitiesData).map(([key, value]) => {
//     // Split the key into month and year
//     const [month, year] = key.split('-');
    
//     // Format the date as "Month Year"
//     const date = new Date(year, month - 1);
//     const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
//     // Return an object with label and value properties
//     return {
//       label: formattedDate,
//       value: value  // Keep the original unformatted data
//     };
//   });

    return(
    
           
          
                <div className="activity-subcontainer">
                    <div className="activity-subcontainer-subcontainer">
                        <div>
                            <div>
                                <img src={activitiesNotes} alt="notesIcon"/>
                                <div >Activities</div>
                            </div>
                            <div>
                                Jump to:
                                <Select
                                    showSearch
                                    placeholder="Select"
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={options}
                                    onChange={handleSelectChange}
                                    className="activity-select"
                                />
                            </div>
                        </div>

                    <div>
                           {/* { selectedOption!==undefined? 
                           <>
                            {filteredSections?.map((section, index) => (
                                <div key={index} className="activities-timeline">
                                    <div>{section.label}</div>
                                    <CustomTimeline options={section.data} />
                                </div>
                            ))}
                            </>
                            :
                            <> */}
                            {sections.map((section, index) => (
                                <div key={index} className="activities-timeline">
                                    <div>{section.label}</div>
                                    <CustomTimeline 
                                        options={section?.data}
                                        onLoadMore={async (nextPage) => {
                                            const response = await fetch(`/api/getActivities?page=${nextPage}`);
                                            return await response.json();
                                          }}
                                          type="activities"
                                    />
                                </div>
                            ))}
                            {/* </>
                            } */}
                     </div>  
                        
                    </div>
                    <div>
                        <SummaryCard/>
                       <div style={{width:'400px'}}> <CustomCarousel slides={slides}/></div>
                    
                    </div>
                  
                </div>
    
    );

}

export default ActivitiesPage