import React, { useState } from 'react';
import './JobIndustrySelector.css'; // You'll define your CSS here
import { useStore } from './store/store';

const JobIndustrySelector = () => {

  const {titleJob,titleIndustry,setTitleJob, setTitleIndustry} = useStore(state=>state)
  const [jobTitle, setJobTitle] = useState('');
  const [industry, setIndustry] = useState('');

  const industries = [ 'IT', 'Healthcare', 'Manufacturing', 'Retail', 'Energy'];

  return (
    <div className="job-industry-selector">
      <input
        type="text"
        className="job-title-input"
        value={titleJob}
        onChange={(e) => setTitleJob(e.target.value)}
        placeholder="Add a job title"
      />
      <span className="divider" style={{height:'20px',width:'1px'}}></span>
      <select
        className="industry-select"
        placeholder="Select an Industry"
        value={titleIndustry}
        onChange={(e) => setTitleIndustry(e.target.value)}
      >
        <option value="" disabled selected hidden style={{color:'gray !important'}}  >
          Select an Industry
        </option>
        {industries.map((industry, index) => (
          <option key={index} value={industry}>
            {industry}
          </option>
        ))}
      </select>
    </div>
  );
};

export default JobIndustrySelector;
