import React, { useEffect, useState } from "react";
import { Timeline } from "antd";
import "./timeline.css";
import parse from 'html-react-parser';

const CustomTimeline = ({options, onLoadMore, type}) => {
  const [activities, setActivities] = useState(type==="activities"?options?.activities:options)
  const [currentPage, setCurrentPage] = useState(type==="activities"?options?.page:1);
  const [hasMore, setHasMore] = useState(options?.loadMore);
  console.log(options,"CHECKTIMELINE")

  const handleClick = async () => {
    try {
      // Trigger the onLoadMore function to fetch more data
      const newData = await onLoadMore(currentPage + 1);

      // Append new activities to the existing list
      setActivities(prevActivities => [...prevActivities, ...newData.activities]);
      
      // Update page and load more status
      setCurrentPage(currentPage + 1);
      setHasMore(newData.loadMore);
    } catch (error) {
      console.error("Error loading more activities:", error);
    }
  };

  
  return type === "activities" ? (
    <>
      <Timeline>
        {activities?.map((item, index) => (
          <Timeline.Item key={index}>
            {parse(item?.info)}
          
            {parse(item?.createdAt.slice(0, 25))}
          </Timeline.Item>
        ))}
      </Timeline>
      {hasMore && (
        <div style={{ color: '#69C0FF', cursor: 'pointer' }} onClick={handleClick}>
          Load more
        </div>
      )}
    </>
  ) : (
    <Timeline>
        {options?.map((item, index) => (
          <Timeline.Item key={index}>
            {parse(item?.info)}
          
            {parse(item?.createdAt.slice(0, 25))}
          </Timeline.Item>
        ))}
      </Timeline>
  );
};


export default CustomTimeline;