import React, { useState } from "react";
import HomeIcon from "../assets/states.svg"
import Home from "../landing/home";
import VacanciesIcon from "../assets/Vacancies.svg"
import UnionIcon from "../assets/Union.svg"
import "./sidebar.css"
import { Link } from "react-router-dom";
import { useStore } from "./store/store";

function SideBar(){

    //const [visible, setVisible] = useState(false);

    const {visible,setVisible} = useStore(state=>state);

    const handleClick =()=>{
        setVisible(!visible);
    }

    return (
        <div style={{display:'flex',flexDirection:'row',height:'100%',position:'relative'}}>
        <div className="sidebar-container">
            <div >
               <Link  to="/"><img src={HomeIcon} alt="HomeIcon" /></Link> 
               <img src={VacanciesIcon} alt="VacanciesIcon" onClick={handleClick }/>
            </div>
            <div
            onClick={handleClick}
             style={{width:'26px',height:'26px',
             backgroundColor:'rgba(255,255,255,0.1)'
             ,borderRadius:'100%',alignSelf:'center',
             justifyContent:'center',cursor:'pointer'}}>
                <img src={UnionIcon} alt="UnionIcon"  style={{rotate:visible?'180deg':'0deg'}}/>

            </div>

        </div>
        {(visible) &&(
            <div className="sidebar-container-expanded">
                <h3>Recruitment Central</h3>
                <h5>MAIN MENU</h5>
             
                <Link  to="/dashboard" className="navlink" onClick={handleClick}>  <li>Dashboard</li></Link>
                  
                <Link  to="/vacancies" className="navlink"  onClick={handleClick}>   <li>Vacancies</li></Link>
                    <li>Resume Library</li>
                    <li>Interviews</li>
                <h5>SETTINGS</h5>
                    <li>Users</li>
                    <li>Hackathon Library</li>
                    <li>Feedback Studio</li>
                    <li>Email Template Builder</li>
                
                    <Link to="/configuration" className="navlink"  onClick={handleClick}>    <li>Configurations</li></Link>
             
            </div>
        )
        }
        </div>
    );
}

export default SideBar;