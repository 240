
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Home from '../landing/home';
import Vacancies from '../pages/vacancies/vacancies';

import JobDescription from '../pages/hiring/jobDescription';
//import TextEditor from './pages/hiring/textEditor';
import CreateJD from '../pages/hiring/createJD';
import Dashboard from '../pages/dashboard/dashboard';
// import SideBar from '../sidebar/sidebar';
import SideBar from "../sidebar/sidebar"
import Header from '../header/header';
import '../App.css'
import RecruitmentOverview from '../pages/vacancies/vacancy_details/recruitmentOverview/recruitmentOverview';
import ActivitiesPage from '../pages/vacancies/vacancy_details/activities/activity';
import VacancyDetails from '../pages/vacancies/vacancy_details/vacancyDetails';
import VacancyWrapper from '../pages/vacancies/vacancyWrapper';
import Configuration from '../pages/settings/configuration';
function MainRouter() {
  return (

<Routes>
  <Route path="/" element={<Home />} />
  <Route path="/createJd" element={<CreateJD />} />
  <Route path="generatedJd" element={<JobDescription />} />
  {/* Shared Layout Route for Dashboard, Vacancies, and Job Description */}
  <Route
    path="/"
    element={
      <div className="app">
        <div className="app-sidebar">
          <SideBar />
        </div>
        <div className="app-main">
          <Header />
          <Outlet  /> 
        </div>
      </div>
    }
  >
    <Route path="dashboard" element={<Dashboard />} />

    <Route path="vacancies" element={<VacancyWrapper/>} >
       <Route path="" element={<Vacancies/>}/>
       <Route  path=":id" element={<VacancyDetails />} />
       <Route path="activities" element={<ActivitiesPage/>}/>
       <Route path='recruitment_overview' element={<RecruitmentOverview/>}/>
    </Route>

    <Route  path='configuration' element={<Configuration/>}/>
 
  </Route>
</Routes>


  );
}

export default MainRouter;
