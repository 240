import React, { useState, useEffect } from 'react';
import './CustomModal.css'; // Optional for additional styling
import GenerateAI from "../../assets/generateAIModal.svg"
import { useStore } from './store/store';
import { useNavigate } from 'react-router-dom';
import { Radio } from 'antd';
import { generateJd } from '../vacancies/Action/action';

const CustomModal = ({ show, handleClose }) => {
  // const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const {progress,setProgress,setIsModalOpen,titleJob,titleIndustry, setJdData} = useStore(state=>state);
  const {setJobExperience, jobExperience,showExperince,setShowExperince} = useStore(state=>state);

  console.log(show,"SHOW",progress)

  useEffect(() => {
    let interval;
 // Reset progress when modal opens
      interval = setInterval(() => {
        if(progress>=100){
              clearInterval(interval);
              setIsModalOpen(false)
              //navigate('/generatedJd');
        }else{
          const newProgress=progress+Math.floor(Math.random()*10);
          if(newProgress<100){
          setProgress(newProgress);
          }
           else{
            setProgress(progress)
           }
        }
   
      }, 500); // Adjust timing for the progress update
  

    return () => clearInterval(interval);
  });


  const jobLevels = [
    { title: 'Entry Level', experience: '0-2 years' },
    { title: 'Junior Level', experience: '2-5 years' },
    { title: 'Mid Level', experience: '5-8 years' },
    { title: 'Senior Level', experience: '8-12 years' },
    { title: 'Manager Level', experience: '12-15 years' },
    { title: 'Principal Level', experience: '15+ years' },
  ];
const callApi=()=>{
  const payload = {
    "role":titleJob,
    "must_have_skills":"",
    "good_to_have_skills": "",
    "years_of_experience": jobExperience,
    "degree_requirements": "",
    "industry":titleIndustry
  };




  generateJd(payload,(response,error)=>{
    setJdData(response?.response);
   
    setIsModalOpen(false);
  })
}
  
  
  const handleNext=()=>{
    setJobExperience();
    setShowExperince(false)
    setProgress(0)
    callApi()


  }
const handleSelect=()=>{
 
  setShowExperince(false)
  setProgress(0);
  callApi()
}

  return (
    <div className="modal">
    {showExperince?
      <div className="modal-content">
      <span className="close" onClick={handleClose}>&times;</span>
      <h3 className='experince-modal-title'>Select experience level</h3>
      <div className='experience-cards'>
      <Radio.Group onChange={(e)=>{
        if(e.target.value==jobExperience)
        setJobExperience()
      else 
      setJobExperience(e.target.value)}} value={jobExperience}>
      {jobLevels?.map(level=>{

        return <div className='level-card'>
        <Radio value={level.experience} checked={level.experience==jobExperience}/>
        <div>
          <h3>{level.title}</h3>
          <p>({level.experience})</p>
        </div>


        </div>
      })}
    </Radio.Group>
      </div>
  
          <button onClick={handleSelect} className="keyword-modal-done" style={{float:'right',background:'#1890FF',color:'white',border:'none'}}>
            Done
          </button>
          <button onClick={handleNext} className="keyword-modal-done" style={{float:'right',marginRight:'10px'}}>
            Skip
          </button>
      </div>

     : <div className="modal-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <img src={GenerateAI} alt="Image"/>
        <div style={{padding:'20px'}}>
        <h3>Generating with AI</h3>
        <p>Hang tight! We're crafting the perfect
        job description for you.</p>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%`}}></div>
          
        </div>
        </div>
        <div className="progress-bar-content">{progress}% completed</div>
      </div>
    }
     
    </div>
  );
};

export default CustomModal;