
import { useEffect, useState } from "react"
import  arrowFill from "../../../../assets/fill.svg"
import  arrowFill1 from "../../../../assets/fill1.svg"
import  arrowFill2 from "../../../../assets/fill2.svg"
import  arrowFill3 from "../../../../assets/fill3.svg"
import  arrowFill4 from "../../../../assets/fill4.svg"
import  arrowFill5 from "../../../../assets/fill5.svg"
import  arrowFilldeep from "../../../../assets/fill6.svg"
import  arrowFill8 from "../../../../assets/fill8.svg"
import  hiringStages from "../../../../assets/hiringStages.svg"
import  templateSelect from "../../../../assets/templateSelect.svg"
import  manageRecruit from "../../../../assets/manageRecruit.svg"
import styles from "./recruit.module.css"
import { Modal, Radio } from "antd"
import ManagementTask from "./managementStage"
import { useGet } from "../../../../custom-hooks/useApi"
import { useVacancyStore } from "../../store/store"

const arrowImgarr=[arrowFill,arrowFill1,arrowFill2,arrowFill3,arrowFill8,arrowFill4,arrowFill5]

const HiringStages = () =>{
    const {defaultTemplate:data1,recruitmentData} = useVacancyStore(state=>state);
      const [templateModal,setTemPlateModal]=useState(false)

    const [value, setValue] = useState();
    const [selectedRadio,setSelectedRadio]=useState()
    const [hoveredIndex, setHoveredIndex] = useState(false);
   const [openDrawer,setOpenDrawer]=useState(false)
   
useEffect(() =>{
  console.log("data",data1,recruitmentData);
setValue(data1?.find(ele =>ele?.templateId===recruitmentData.templateId)?.templateId)
setSelectedRadio(data1?.find(ele =>ele?.templateId===recruitmentData.templateId)?.templateId)
},[recruitmentData,data1])
    const onChange = (e) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
 return(
    <>
    <div style={{display:"flex",justifyContent:"space-between"}}>
        <div className={styles.modalTitle} style={{display:"flex",flexDirection:"row"}}>
            <img src={hiringStages}/>
            <div style={{margin:8}}>Hiring Process Stages</div></div>
        <div style={{display:"flex",margin:8}}>
        <div style={{color:"#FA8C16",cursor:"pointer",}} onClick={() =>setTemPlateModal(true)}><img src={templateSelect}></img> Select from template</div>
        <div style={{color:"#1890FF",cursor:"pointer",marginLeft:8}} onClick={() =>setOpenDrawer(true)}> <img src={manageRecruit}></img> Manage</div></div></div>
          <div style={{display:"flex",flexDirection:"row",width:"100%",height:"10%"}}>
         
            {data1?.find(ele =>ele.templateId==selectedRadio)?.templateStages.map((ele,index,arr) =>(
                  
                <div key={index} style={{position:"relative",width:"14%",height:"100%"}}>
                    <img src={index==arr.length-1?arrowFilldeep:index>6?arrowFill5: arrowImgarr[index]} height={"100%"} width={"100%"} />
                    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "1.2em",
        // color: "white", // Adjust color for better visibility
        // fontWeight: "bold",
        // textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" // Shadow for better visibility
      }}
    >
      {index + 1}
    </div>
                </div>
            ))}

          </div>
          <div style={{minHeight:"80%",}}>
            {data1?.find(ele =>ele.templateId===selectedRadio)?.templateStages.map((ele,index) =>{
                return(
                <div style={{width:"98%",border: "1px solid var(--boarders-lines-neutral-light, #E9E9E9)",display:"flex",flexDirection:"row",gap:16,height:"80px",marginTop:"16px"}}>
                    <div style={{alignSelf:"center",marginLeft:"4px"}}>{" "+ (index+1) + "."} </div>
                    <div style={{alignSelf:"center"}}>
                <div className={styles.title}  >{ele?.stageName}</div>
                {ele?.stageDescription}
                </div>
                
                </div>
            )})}
          </div>
        <Modal 
        open={templateModal}
        onCancel={() =>setTemPlateModal(false)}
        onClose={() =>setTemPlateModal(false)}
        width={"60%"}
        okText="Select"
        onOk={() =>{
            setSelectedRadio(value)
            setTemPlateModal(false)
        }}
        
        >
            <div style={{height:"500px"}}>
                <div className={styles.modalTitle}>Templates</div>
                <div style={{marginBottom:24}}>Choose from pre-defined templates to quickly set up your hiring process.</div>
          <Radio.Group  style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:8}} onChange={onChange} value={value}>
            {
                data1?.map((ele, index) =>{
                    return(
                        <div 
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        className={styles.templateBox} style={{width:"40%",    border: `1px solid ${hoveredIndex === index ? "#1890FF" : "#E9E9E9"}`,
                        transition: "border-color 0.3s ease",}}>
                          <Radio 
                          key={ele?.id}
                         value={ele?.templateId}>{ele?.templateName}</Radio>  
                        </div>
                    )
                })
            }
      
    </Radio.Group>
    </div>
        </Modal>
        {openDrawer&&<ManagementTask openDrawer={openDrawer} onClose={() =>setOpenDrawer(false)} data={data1?.find(ele =>ele.templateId==selectedRadio)}/>}
    </>
 )
}

export default HiringStages